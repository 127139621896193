import api from "../../AxiosConfig/Config";
import { createPasswordInfo, RegisterInfo, verifyOtp } from "./type";
import moment from "moment-timezone";

export const getTimezoneQuery = () => {
  const timezone = moment.tz.guess();
  return api.put(`account/timezone/`, {
    timezone,
  });
};

export const getChatTokenQuery = () => {
  return api.get(`chat/token/`);
};

export const handleLoginQuery = (formData: FormData) => {
  return api.post(`/account/login/`, formData);
};

export const sentOtpToPhoneNumberLogin = (formData: FormData) => {
  return api.post(`/account/otp/`, formData);
};

export const verifyOtpQuery = ({
  phone_number,
  activation_code,
}: verifyOtp) => {
  return api.post(`/account/otp/confirm/`, {
    phone_number,
    activation_code,
  });
};

export const sentOtpToPhoneNumberRegister = (phone_number: string) => {
  return api.post(`/account/register/`, {
    phone_number,
  });
};

export const handleRegisterQuery = ({
  phone_number,
  password,
  activation_code,
  user_agent_identifier,
}: RegisterInfo) => {
  return api.post(`/account/register/confirm/`, {
    phone_number,
    password,
    activation_code,
    user_agent_identifier,
  });
};

export const forgotPasswordQuery = (phone_number: string) => {
  return api.post(`/account/forgot-password/`, {
    phone_number,
  });
};

export const forgotPasswordConfirmQuery = ({
  phone_number,
  password,
  passwordConfirmation,
  activation_code,
}: createPasswordInfo) => {
  return api.post(`/account/forgot-password/confirm/`, {
    phone_number,
    password,
    passwordConfirmation,
    activation_code,
  });
};
