import React, { useEffect, useRef, useState } from "react";
import { Form as ReactForm } from "react-bootstrap";
import AuthLayout from "@components/Layouts/AuthLayout";
import eyeIcon from "@assets/icons/eye.svg";
import styles from "@scss/module/Form.module.scss";
import Link from "next/link";
import Image from "next/image";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import { getCookie, setCurrentUser } from "@utils/index";
import Loader from "@components/Common/Loader";
import authConfig from "@utils/auth";
import { handleLoginQuery } from "@services/DataAction/Auth";
import { SaveUserDto } from "redux/user/action";
import Lottie from "react-lottie";
import login from "@assets/animations/login.json";
import { toast } from "react-toastify";
import * as amplitude from "@amplitude/analytics-browser";

const validationSchema = Yup.object().shape({
  phone_number: Yup.string().required("Please enter a valid phone number"),
  password: Yup.string().required("Password is required"),
});
type FormValues = {
  phone_number: string;
  password: string;
};
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: login,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const Login = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const formikRef = useRef<FormikProps<FormValues>>(null);
  const [loading, setLoading] = useState(false);
  const [passwordEye, setPasswordEye] = useState(true);
  const [initialValue] = useState({
    phone_number: "",
    password: "",
  });
  const handlePasswordEye = () => {
    setPasswordEye(!passwordEye);
  };

  useEffect(() => {
    if (!!localStorage.getItem(authConfig.storageTokenKeyName)) {
      router.push("/patient");
    }
  }, []);

  const handleOnSubmit = async (
    values: { phone_number: string; password: string },
    setSubmitting: { (isSubmitting: boolean): void; (arg0: boolean): void }
  ) => {
    setSubmitting(true);
    handleLogin(values);
    setSubmitting(false);
  };

  const handleLogin = async (data: {
    phone_number: string;
    password: string;
  }) => {
    try {
      setLoading(true);
      const formData = new FormData();
      const user_agent_identifier = getCookie("user_agent_identifier");
      formData.append("phone_number", data.phone_number);
      formData.append("password", data.password);
      formData.append("user_agent_identifier", user_agent_identifier);
      const result = await handleLoginQuery(formData);
      if (result) {
        amplitude.setUserId(result.data.body.profile.last_name);
        dispatch(SaveUserDto(result.data.body.profile));
        setCurrentUser(result.data.body);
        setLoading(false);
        await router.push("/patient");
      }
    } catch (error: any) {
      setLoading(false);
      if (formikRef && formikRef.current) {
        const errorMessage =
          error?.data?.message ?? "An unexpected error occurred";
        formikRef.current.setErrors(errorMessage);
      }
      if (error?.status === 403) {
        await router.push({
          pathname: "/otp-verification",
          query: { phoneNumber: data.phone_number },
        });
      } else if (error?.status === 401) {
        toast.error(error.data.message);
      }
    }
  };

  return (
    <AuthLayout>
      <div className={styles.formWrapper}>
        <div className="mt-4 mb-2">
          <Lottie options={defaultOptions} height={250} width={350} />
        </div>

        <h4>Login</h4>
        <Formik
          innerRef={formikRef}
          initialValues={initialValue}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleOnSubmit(values, setSubmitting);
          }}
          enableReinitialize
        >
          {({ isSubmitting, errors, handleChange, touched, values }) => (
            <Form className={styles.form}>
              <div className={styles.formFields}>
                <ReactForm.Group className="mb-3" controlId="formBasicEmail">
                  <ReactForm.Label>Phone Number</ReactForm.Label>
                  <ReactForm.Control
                    type="text"
                    placeholder="Enter Phone Number"
                    onChange={(e) => {
                      const re = /^[0-9\b\+]+$/;
                      if (e.target.value === "" || re.test(e.target.value)) {
                        handleChange(e);
                      } else {
                        e.preventDefault();
                      }
                    }}
                    name="phone_number"
                    className={
                      touched.phone_number && errors.phone_number
                        ? "border-danger"
                        : ""
                    }
                    value={values.phone_number}
                  />
                  {touched.phone_number && errors.phone_number ? (
                    <div className="error-message text-danger">
                      {errors.phone_number}
                    </div>
                  ) : null}
                </ReactForm.Group>
                <ReactForm.Group controlId="formBasicPassword">
                  <div className={styles.labelWrapper}>
                    <ReactForm.Label>Enter Password</ReactForm.Label>
                    <span>
                      <Link href="/forgot-password" passHref={true}>
                        Forgot Password?
                      </Link>
                    </span>
                  </div>
                  <div className={styles.passwordEye}>
                    <ReactForm.Control
                      onChange={handleChange}
                      name="password"
                      className={
                        errors.password && touched.password
                          ? "border-danger"
                          : ""
                      }
                      value={values.password}
                      type={passwordEye ? "password" : "text"}
                      placeholder="Enter Password"
                    />
                    <span
                      className={`${styles.passwordEyeImg} ${
                        passwordEye ? styles.hidePassword : ""
                      }`}
                      onClick={handlePasswordEye}
                    >
                      <Image src={eyeIcon} alt="" />
                    </span>
                  </div>

                  {touched.password && errors.password ? (
                    <div className="error-message text-danger">
                      {errors.password}
                    </div>
                  ) : null}
                </ReactForm.Group>
                <div className={styles.form_btns}>
                  <button
                    type="submit"
                    className="btn filled"
                    disabled={loading}
                  >
                    {loading && <Loader />}
                    {"  "}Next
                  </button>
                  <h5 className={styles.signup_title}>
                    {" "}
                    No account?{" "}
                    <span onClick={() => router.push("/signup")}>Sign up</span>
                  </h5>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </AuthLayout>
  );
};

export default Login;
