import React from "react";
import styles from "./Loader.module.scss";

const Loader = () => {
  return (
    <span id={styles.wave}>
      <span className={styles.dot}></span>
      <span className={styles.dot}></span>
      <span className={styles.dot}></span>
    </span>
  );
};

export default Loader;
