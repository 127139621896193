import React from "react";
import { Col, Row } from "react-bootstrap";
import styles from "./AuthLayout.module.scss";
import logoWhite from "@assets/logoWhite.svg";
import Image from "next/image";
import Lottie from "react-lottie";
import loginBg from "@assets/animations/login-bg.json";
interface IProps {
  children: React.ReactNode;
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loginBg,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const AuthLayout = ({ children }: IProps) => {
  return (
    <div className={styles.authLayoutWrap}>
      <Row className="gx-0 h-100">
        <Col md={6}>
          <div className={styles.authLayoutBg}>
            <Lottie
              speed={0.4}
              isClickToPauseDisabled={true}
              options={defaultOptions}
            />
            <div className={styles.authLayoutLogo}>
              <Image src={logoWhite} alt="logo" />
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className={styles.authLayoutFormWrap}>{children}</div>
        </Col>
      </Row>
    </div>
  );
};

export default AuthLayout;
